import * as React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Navbar from "../components/navbar";
import styled from "@emotion/styled";
import { GtagInit, TrackConversion } from "../components/conversion";

const FormContainer = styled.div`
  background-image: url("${(props: any) => props.image}");
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 70px);
  label {
    font-weight: bold;
  }
  .form-group {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .form-box {
    background-color: rgba(255, 255, 255, 0.95);
    max-width: 850px;
    padding: 8px;
    @media (min-width: 640px){
      padding: 16px;
    }
  }
  .form-column {
    max-width: 300px;
    margin: auto;
    padding: 0px 8px;
  }
`;

const Form = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DevisSuccessFormImage {
      image: file(relativePath: { eq: "bg-chantier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 1024) {
            src
          }
        }
      }
    }
  `);
  return (
    <FormContainer
      className="container-fluid pt-2 pt-sm-5 mb-sm-4"
      image={data.image.childImageSharp.fluid.src}
    >
      <div className="container mt-5 px-0 px-sm-2">
        <div className="form-box mx-auto">{children}</div>
      </div>
    </FormContainer>
  );
};

const DevisSuccess = (props: PageProps) => {
  return (
    <>
      <Navbar />
      <Form>
        <h1
          className="h3 text-danger text-center pt-3"
          style={{ textTransform: "uppercase" }}
        >
          Merci de votre confiance
        </h1>
        <div className="p-4">
          <p className="lead text-center font-weight-bold">
            Votre demande a bien été enregistrée, <br />
            un de nos conseillers vous contactera très prochainement.
          </p>
          <GtagInit />
          <TrackConversion />
        </div>
      </Form>
    </>
  );
};

export default DevisSuccess;
